import React, { Suspense, Fragment, lazy } from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';

// import NotFoundView from './views/errors/NotFoundView';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i} // eslint-disable-line react/no-array-index-key
            path={route.path}
            exact={route.exact}
            element={
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component />}
                </Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    path: '/email_404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    path: '/wallet/:token',
    component: lazy(() => import('src/views/wallet')),
  },
  {
    exact: true,
    path: '/payment',
    component: lazy(() => import('src/views/payment')),
  },
  {
    path: '/app/*',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/account',
        component: lazy(() => import('src/views/account/SettingsView')),
      },
      {
        exact: true,
        path: '/map',
        component: lazy(() => import('src/components/map')),
      },
      // {
      //   exact: true,
      //   path: '/app/qrcode',
      //   component: lazy(() => import('src/views/qrcode')),
      // },
      {
        exact: true,
        path: '/',
        component: lazy(() => import('src/views/main')),
      },
      {
        component: () => <Navigate to="/404" />,
      },
    ],
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        guard: GuestGuard,
        component: lazy(() => import('src/views/auth/LoginView')),
      },
      {
        exact: true,
        path: '/register',
        guard: GuestGuard,
        component: lazy(() => import('src/views/auth/RegisterView')),
      },
      {
        exact: true,
        path: '/forgot-password',
        guard: GuestGuard,
        component: lazy(() => import('src/views/auth/ForgotPassword')),
      },
      {
        path: '/password-reset/:uidb64/:token',
        // guard: GuestGuard,
        component: lazy(() => import('src/views/auth/ResetPasswordView')),
      },
      {
        component: () => <Navigate to="/404" />,
      },
    ],
  },
];

export default routes;
