import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { axiosMockInstance as axios } from '../utils/axios';

const initialState = {
  events: [],
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // Set events.
    getEvents(state, action) {
      const { events } = action.payload;

      state.events = events;
    },
    // Add new event to events currently stored in state.
    createEvent(state, action) {
      const { event } = action.payload;

      state.events = [...state.events, event];
    },
    // Store id of event that has been selected. Also change boolean to show modal.
    selectEvent(state, action) {
      const { eventId = null } = action.payload;

      state.isModalOpen = true;
      state.selectedEventId = eventId;
    },
    // Update specific event in events array.
    updateEvent(state, action) {
      const { event } = action.payload;

      state.events = _.map(state.events, (_event) => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    },
    // Remove event from events array in state.
    deleteEvent(state, action) {
      const { eventId } = action.payload;

      state.events = _.reject(state.events, { id: eventId });
    },
    // Update range stored in state.
    selectRange(state, action) {
      const { start, end } = action.payload;

      state.isModalOpen = true;
      state.selectedRange = {
        start,
        end,
      };
    },
    // Change variable to show modal.
    openModal(state) {
      state.isModalOpen = true;
    },
    // Reset all state variables that control modals.
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

export const { reducer } = slice;

// Set events from http request response in state.
export const getEvents = () => async (dispatch) => {
  const response = await axios.get('/api/calendar/events');

  dispatch(slice.actions.getEvents(response.data));
};

// Add new event to state after from http request.
export const createEvent = (data) => async (dispatch) => {
  const response = await axios.post('/api/calendar/events/new', data);

  dispatch(slice.actions.createEvent(response.data));
};

// Pass event id of selected event to store in state.
export const selectEvent = (eventId) => async (dispatch) => {
  dispatch(slice.actions.selectEvent({ eventId }));
};

// Change updated event in state after http request.
export const updateEvent = (eventId, update) => async (dispatch) => {
  const response = await axios.post('/api/calendar/events/update', {
    eventId,
    update,
  });

  dispatch(slice.actions.updateEvent(response.data));
};

// Remove event from state after http request.
export const deleteEvent = (eventId) => async (dispatch) => {
  await axios.post('/api/calendar/events/remove', {
    eventId,
  });

  dispatch(slice.actions.deleteEvent({ eventId }));
};

// Pass in range values to state variable(s).
export const selectRange = (start, end) => (dispatch) => {
  dispatch(
    slice.actions.selectRange({
      start: start.getTime(),
      end: end.getTime(),
    })
  );
};

// Function to open modal.
export const openModal = () => (dispatch) => {
  dispatch(slice.actions.openModal());
};

// Function to close modal.
export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
