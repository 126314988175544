import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ iswhite, ...rest }) =>
  iswhite ? (
    <img alt="Logo" src="/static/swap_box_white.png" {...rest} />
  ) : (
    <img alt="Logo" src="/static/swap_box.png" {...rest} />
  );

Logo.propTypes = {
  iswhite: PropTypes.bool,
};

export default Logo;
