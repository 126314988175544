import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { axiosMockInstance as axios } from '../utils/axios';
import objFromArray from '../utils/objFromArray';

const initialState = {
  isLoaded: false,
  lists: {
    byId: {},
    allIds: [],
  },
  cards: {
    byId: {},
    allIds: [],
  },
  members: {
    byId: {},
    allIds: [],
  },
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    // Create Objects for lists, cards & members where each property is a list, card & member, and each key is the id (objFromArray) and store in state.
    // Create arrays for list ids, card ids & member ids and store in state.
    getBoard(state, action) {
      const { board } = action.payload;

      state.lists.byId = objFromArray(board.lists);
      state.lists.allIds = Object.keys(state.lists.byId);
      state.cards.byId = objFromArray(board.cards);
      state.cards.allIds = Object.keys(state.cards.byId);
      state.members.byId = objFromArray(board.members);
      state.members.allIds = Object.keys(state.members.byId);
      state.isLoaded = true;
    },
    // Add list in lists opject.
    // Also add new list id to list ids array.
    createList(state, action) {
      const { list } = action.payload;

      state.lists.byId[list.id] = list;
      state.lists.allIds.push(list.id);
    },
    // Update specific list in lists object
    updateList(state, action) {
      const { list } = action.payload;

      state.lists.byId[list.id] = list;
    },
    // Set specific list to empty array.
    // Remove cards that have the ids of those stored in the cleared list. Also remove those ids from the cards ids array.
    clearList(state, action) {
      const { listId } = action.payload;
      const { cardIds } = state.lists.byId[listId];

      state.lists.byId[listId].cardIds = [];
      state.cards.byId = _.omit(state.cards.byId, cardIds);
      _.pull(state.cards.allIds, ...cardIds);
    },
    // Remove list from the lists object, as well as id from list ids array.
    deleteList(state, action) {
      const { listId } = action.payload;

      state.lists.byId = _.omit(state.lists.byId, listId);
      _.pull(state.lists.allIds, listId);
    },
    // Add card to cards object and add it to card ids array.
    // Also add card id to card ids array of list it is assigned to.
    createCard(state, action) {
      const { card } = action.payload;

      state.cards.byId[card.id] = card;
      state.cards.allIds.push(card.id);
      state.lists.byId[card.listId].cardIds.push(card.id);
    },
    // Update any data that has changed for specific card.
    updateCard(state, action) {
      const { card } = action.payload;

      _.merge(state.cards.byId[card.id], card);
    },
    // Remove card from source list
    // If listId arg exists, it means that we have to add the card to the new list.
    moveCard(state, action) {
      const { cardId, position, listId } = action.payload;
      const { listId: sourceListId } = state.cards.byId[cardId];

      _.pull(state.lists.byId[sourceListId].cardIds, cardId);

      if (listId) {
        state.cards.byId[cardId].listId = listId;
        state.lists.byId[listId].cardIds.splice(position, 0, cardId);
      } else {
        state.lists.byId[sourceListId].cardIds.splice(position, 0, cardId);
      }
    },
    // Remove card from cards object and its id from the cards ids array.
    // Also remove id from card ids array in the list it is assigned to.
    deleteCard(state, action) {
      const { cardId } = action.payload;
      const { listId } = state.cards.byId[cardId];

      state.cards.byId = _.omit(state.cards.byId, cardId);
      _.pull(state.cards.allIds, cardId);
      _.pull(state.lists.byId[listId].cardIds, cardId);
    },
    // Add new comment to comments array to specfic card.
    addComment(state, action) {
      const { comment } = action.payload;
      const card = state.cards.byId[comment.cardId];

      card.comments.push(comment);
    },
    // Add new checklist to checklists array to specfic card.
    addChecklist(state, action) {
      const { cardId, checklist } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists.push(checklist);
    },
    // Update certain checklist in array for specfic card.
    updateChecklist(state, action) {
      const { cardId, checklist } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = _.map(card.checklists, (_checklist) => {
        if (_checklist.id === checklist.id) {
          return checklist;
        }

        return _checklist;
      });
    },
    // Remove certain checklist from array in specific card.
    deleteChecklist(state, action) {
      const { cardId, checklistId } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = _.reject(card.checklists, { id: checklistId });
    },
    // Add a new checkitem to an individual checklist in the checklist array of specific card
    addCheckItem(state, action) {
      const { cardId, checklistId, checkItem } = action.payload;
      const card = state.cards.byId[cardId];

      _.assign(card, {
        checklists: _.map(card.checklists, (checklist) => {
          if (checklist.id === checklistId) {
            _.assign(checklist, {
              checkItems: [...checklist.checkItems, checkItem],
            });
          }

          return checklist;
        }),
      });
    },
    // Update checkitem in an individual checklist in the checklist array of specific card.
    updateCheckItem(state, action) {
      const { cardId, checklistId, checkItem } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = _.map(card.checklists, (checklist) => {
        if (checklist.id === checklistId) {
          _.assign(checklist, {
            checkItems: _.map(checklist.checkItems, (_checkItem) => {
              if (_checkItem.id === checkItem.id) {
                return checkItem;
              }

              return _checkItem;
            }),
          });
        }

        return checklist;
      });
    },
    // Remove checkitem from an individual checklist in the checklist array of specific card.
    deleteCheckItem(state, action) {
      const { cardId, checklistId, checkItemId } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = _.map(card.checklists, (checklist) => {
        if (checklist.id === checklistId) {
          _.assign(checklist, {
            checkItems: _.reject(checklist.checkItems, { id: checkItemId }),
          });
        }

        return checklist;
      });
    },
  },
});

export const { reducer } = slice;

// Set lists, cards & members from http request response in state.
export const getBoard = () => async (dispatch) => {
  const response = await axios.get('/api/kanban/board');

  dispatch(slice.actions.getBoard(response.data));
};

// Add new list from http request response in state.
export const createList = (name) => async (dispatch) => {
  const response = await axios.post('/api/kanban/lists/new', {
    name,
  });

  dispatch(slice.actions.createList(response.data));
};

// Update list from with data from http request response in state.
export const updateList = (listId, update) => async (dispatch) => {
  const response = await axios.post('/api/kanban/list/update', {
    listId,
    update,
  });

  dispatch(slice.actions.updateList(response.data));
};

// Empty array and remove any cards that were assigned to the list after http request.
export const clearList = (listId) => async (dispatch) => {
  await axios.post('/api/kanban/lists/clear', {
    listId,
  });

  dispatch(slice.actions.clearList({ listId }));
};

// Remove list from state after making http request.
export const deleteList = (listId) => async (dispatch) => {
  await axios.post('/api/kanban/lists/remove', {
    listId,
  });

  dispatch(slice.actions.deleteList({ listId }));
};

// Add new card to state with data from http request response.
export const createCard = (listId, name) => async (dispatch) => {
  const response = await axios.post('/api/kanban/cards/new', {
    listId,
    name,
  });

  dispatch(slice.actions.createCard(response.data));
};

// Update card from with data from http request response in state.
export const updateCard = (cardId, update) => async (dispatch) => {
  const response = await axios.post('/api/kanban/cards/update', {
    cardId,
    update,
  });

  dispatch(slice.actions.updateCard(response.data));
};

// Remove and add card to new position after making http request.
export const moveCard = (cardId, position, listId) => async (dispatch) => {
  await axios.post('/api/kanban/cards/move', {
    cardId,
    position,
    listId,
  });

  dispatch(
    slice.actions.moveCard({
      cardId,
      position,
      listId,
    })
  );
};

// Remove card from state after making http request.
export const deleteCard = (cardId) => async (dispatch) => {
  await axios.post('/api/kanban/cards/remove', {
    cardId,
  });

  dispatch(slice.actions.deleteCard({ cardId }));
};

// Add new comment to a card in state using data from http request response.
export const addComment = (cardId, message) => async (dispatch) => {
  const response = await axios.post('/api/kanban/comments/new', {
    cardId,
    message,
  });

  dispatch(slice.actions.addComment(response.data));
};

// Add new checklist to a card in state using data from http request response.
export const addChecklist = (cardId, name) => async (dispatch) => {
  const response = await axios.post('/api/kanban/checklists/new', {
    cardId,
    name,
  });
  const { checklist } = response.data;

  dispatch(
    slice.actions.addChecklist({
      cardId,
      checklist,
    })
  );
};

// Update a checklist in a card in state using data from http request response
export const updateChecklist =
  (cardId, checklistId, update) => async (dispatch) => {
    const response = await axios.post('/api/kanban/checklists/update', {
      cardId,
      checklistId,
      update,
    });
    const { checklist } = response.data;

    dispatch(
      slice.actions.updateChecklist({
        cardId,
        checklist,
      })
    );
  };

// Remove a checklist from a card in state after http request
export const deleteChecklist = (cardId, checklistId) => async (dispatch) => {
  await axios.post('/api/kanban/checklists/remove', {
    cardId,
    checklistId,
  });

  dispatch(
    slice.actions.deleteChecklist({
      cardId,
      checklistId,
    })
  );
};

// Add new checkitem to a checklist for a card in state using data from http request response
export const addCheckItem = (cardId, checklistId, name) => async (dispatch) => {
  const response = await axios.post('/api/kanban/checkitems/new', {
    cardId,
    checklistId,
    name,
  });
  const { checkItem } = response.data;

  dispatch(
    slice.actions.addCheckItem({
      cardId,
      checklistId,
      checkItem,
    })
  );
};

// Update an exisiting checkitem in a checklist for a card in state using data from http request response
export const updateCheckItem =
  (cardId, checklistId, checkItemId, update) => async (dispatch) => {
    const response = await axios.post('/api/kanban/checkitems/update', {
      cardId,
      checklistId,
      checkItemId,
      update,
    });
    const { checkItem } = response.data;

    dispatch(
      slice.actions.updateCheckItem({
        cardId,
        checklistId,
        checkItem,
      })
    );
  };

// Remove a checkitem from a checklist for a card in state after http request.
export const deleteCheckItem =
  (cardId, checklistId, checkItemId) => async (dispatch) => {
    await axios.post('/api/kanban/checkitems/remove', {
      cardId,
      checklistId,
      checkItemId,
    });

    dispatch(
      slice.actions.deleteCheckItem({
        cardId,
        checklistId,
        checkItemId,
      })
    );
  };

export default slice;
