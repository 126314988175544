import React, { useEffect } from 'react';
import {
  useLocation,
  matchPath,
  Link as RouterLink,
  useNavigate,
} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, Drawer, Hidden, List, makeStyles } from '@material-ui/core';
import {
  Share2 as ShareIcon,
  Map as MapIcon,
  LogOut as LogOutIcon,
  User as UserIcon,
} from 'react-feather';
import { useSnackbar } from 'notistack';
// import CropFreeIcon from '@material-ui/icons/CropFreeOutlined';
import HomeIcon from '@material-ui/icons/Home';
import LiveHelpIcon from '@material-ui/icons/LiveHelpOutlined';
import HighlightIcon from '@material-ui/icons/HighlightOutlined';
import { useTranslation } from 'react-i18next';
import Logo from '../../../components/Logo';
import NavItem from './NavItem';
import NavDownloadButton from './NavDownloadButton';
import useAuth from '../../../hooks/useAuth';

// Nav items.
const genGeneralSection = (t) => ({
  items: [
    {
      title: t('navBar.sections.home'),
      href: '/app',
      icon: HomeIcon,
    },
    // {
    //   title: t('navBar.sections.qrCode'),
    //   href: '/app/qrcode',
    //   icon: CropFreeIcon,
    // },
    {
      title: t('navBar.sections.locator'),
      href: '/app/map',
      icon: MapIcon,
    },
    {
      title: t('navBar.sections.loyaltyProgram'),
      href: 'https://www.swap-box.com/loyalty-program',
      isExternalLink: true,
      target: '_blank',
      icon: ShareIcon,
    },
    {
      title: t('navBar.sections.howItWorks'),
      href: 'https://www.swap-box.com/how-it-works',
      isExternalLink: true,
      target: '_blank',
      icon: HighlightIcon,
    },
    {
      title: t('navBar.sections.faq'),
      href: 'https://www.swap-box.com/faq',
      isExternalLink: true,
      target: '_blank',
      icon: LiveHelpIcon,
    },
  ],
});

// Nav items only shown on Mobile screen sizes.
const genMobileSection = (t) => ({
  items: [
    {
      title: t('navBar.sections.account'),
      href: '/app/account',
      icon: UserIcon,
    },
  ],
});

function renderNavItems({ items, pathname, depth = 0 }, logoutFunction, t) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          // eslint-disable-next-line no-use-before-define
          reduceChildRoutes({
            acc,
            item,
            pathname,
            depth,
          }),
        []
      )}
      <Hidden lgUp>
        <div
          onClick={logoutFunction}
          onKeyDown={() => {}}
          role="menuitem"
          tabIndex="0"
        >
          <NavItem
            href="#"
            icon={LogOutIcon}
            title={t('navBar.sections.logout')}
            isExternalLink
            depth={0}
            open
          />
        </div>
      </Hidden>
    </List>
  );
}

// Function to determine if child paths are present, and if additional nav items nodes should be added.
function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        isExternalLink={item.isExternalLink}
        target={item.target}
        title={item.title}
      />
    );
  }
  return acc;
}

// Element styling.
const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  logoIcon: {
    height: 50,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const desktoplSection = genGeneralSection(t);
  const mobileSection = {
    items: [...genGeneralSection(t).items, ...genMobileSection(t).items],
  };
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('topBar.logoutNotification'), {
        variant: 'error',
      });
    }
  };

  // If on mobile screen, close nav menu when url changes.
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (section, location) => (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        className={classes.mainContainer}
      >
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo className={classes.logoIcon} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          {renderNavItems(
            {
              items: section.items,
              pathname: location.pathname,
            },
            handleLogout,
            t
          )}
        </Box>
        <Hidden mdDown>
          <NavDownloadButton />
        </Hidden>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content(mobileSection, location)}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content(desktoplSection, location)}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
