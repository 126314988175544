import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import './assets/css/prism.css';
import React, { Suspense } from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { enableES5 } from 'immer';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { SettingsProvider } from './contexts/SettingsContext';
import App from './App';
import './i18n';
import LoadingScreen from './components/LoadingScreen';

enableES5();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <Provider store={store}>
    <Suspense fallback={<LoadingScreen />}>
      <SettingsProvider>
        <App />
      </SettingsProvider>
    </Suspense>
  </Provider>
);

serviceWorker.register();
