import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  IconButton,
  Popover,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Settings as SettingsIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import useSettings from '../../../hooks/useSettings';
import { LANGUAGES } from '../../../constants';

// Element styling.
const useStyles = makeStyles((theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5,
  },
  popover: {
    width: 320,
    padding: theme.spacing(2),
  },
  iconColor: {
    color: '#fff',
  },
}));

const Settings = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const ref = useRef(null);
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
    language: settings.language,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value,
    });
  };

  const handleSave = () => {
    saveSettings(values);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Settings">
        <IconButton
          color="inherit"
          className={className}
          onClick={handleOpen}
          ref={ref}
        >
          <SvgIcon fontSize="small" className={classes.iconColor}>
            <SettingsIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography variant="h4" color="textPrimary">
          {t('topBar.settingsTitle')}
        </Typography>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Language"
            name="language"
            onChange={(event) => handleChange('language', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.language}
            variant="outlined"
          >
            {Object.keys(LANGUAGES).map((language) => (
              <option key={language} value={LANGUAGES[language]}>
                {language}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSave}
          >
            {t('topBar.settingsButton.title')}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

Settings.propTypes = {
  className: PropTypes.string,
};

export default Settings;
