export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const PHONE_REGEX = /^\+(?:[0-9]●?){11}[0-9]$/;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
};

export const LANGUAGES = {
  English: 'en',
  French: 'fr',
  Dutch: 'nl',
};

export const BORROW_DAYS = 14;
