import { createSlice } from '@reduxjs/toolkit';
import { axiosMockInstance as axios } from '../utils/axios';

const initialState = {
  notifications: [],
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    // Set notifications
    getNotifications(state, action) {
      const { notifications } = action.payload;

      state.notifications = notifications;
    },
  },
});

export const { reducer } = slice;

// Set notifications from http request response in state.
export const getNotifications = () => async (dispatch) => {
  const response = await axios.get('/api/notifications');

  dispatch(slice.actions.getNotifications(response.data));
};

export default slice;
