import React from 'react';
import clsx from 'clsx';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import appleIcon from '../../assets/icons/apple-icon.svg';
import googleAppIcon from '../../assets/icons/google-play-icon.svg';

// Element styling.
const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },
  buttonContainer: {
    backgroundColor: '#007bff4a',
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.75rem 0.25rem',
    textDecoration: 'none',
    borderRadius: '0.5rem',
    minWidth: '8rem',
    [theme.breakpoints.down('xs')]: {
      minWidth: '7rem',
    },
  },
  leftBtn: {
    marginRight: '1rem',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0.5rem',
    },
  },
  icon: {
    height: '1.5rem',
    display: 'inline-block',
    marginRight: '0.5rem',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0.25rem',
    },
  },
  texts: {
    display: 'inline-block',
  },
  firstText: {
    fontSize: '0.4rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    display: 'block',
  },
  seconedText: {
    display: 'inline-block',
    fontSize: '0.8rem',
    lineHeight: '1',
  },
  link: {
    display: 'inline-block',
    textDecoration: 'none',
  },
}));

const DownloadBtn = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Box className={classes.buttons}>
        <a
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://apps.apple.com/app/swapbox-zero-waste-takeaway/id1563620024"
        >
          <Box className={clsx(classes.buttonContainer, classes.leftBtn)}>
            <img
              src={appleIcon}
              alt={t('views.main.appStore')}
              className={classes.icon}
            />
            <Box className={classes.texts}>
              <Typography variant="body1" className={classes.firstText}>
                {t('views.main.downloadOnThe')}
              </Typography>
              <Typography variant="body1" className={classes.seconedText}>
                {t('views.main.appStore')}
              </Typography>
            </Box>
          </Box>
        </a>
        <a
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://play.google.com/store/apps/details?id=com.swapbox.consumer"
        >
          <Box className={classes.buttonContainer}>
            <img
              src={googleAppIcon}
              alt={t('views.main.googlePlay')}
              className={classes.icon}
            />
            <Box className={classes.texts}>
              <Typography variant="body1" className={classes.firstText}>
                {t('views.main.getItOn')}
              </Typography>
              <Typography variant="body1" className={classes.seconedText}>
                {t('views.main.googlePlay')}
              </Typography>
            </Box>
          </Box>
        </a>
      </Box>
    </div>
  );
};

export default DownloadBtn;
