import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider,
  createTheme,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { enUS, frFR, nlNL } from '@material-ui/core/locale';
import GlobalStyles from './components/GlobalStyles';
import ScrollReset from './components/ScrollReset';
import { AuthProvider } from './contexts/JWTAuthContext';
import useSettings from './hooks/useSettings';
import { makeTheme } from './theme';
import routes, { renderRoutes } from './routes';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();
const locales = {
  en: enUS,
  fr: frFR,
  nl: nlNL,
};

const App = () => {
  const { settings } = useSettings();

  const theme = makeTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <ThemeProvider
        theme={(outerTheme) =>
          createTheme(outerTheme, locales[settings.language])
        }
      >
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider dense maxSnack={3}>
              <BrowserRouter location={history.location}>
                <AuthProvider>
                  <GlobalStyles />
                  <ScrollReset />
                  {renderRoutes(routes)}
                </AuthProvider>
              </BrowserRouter>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </ThemeProvider>
  );
};

export default App;
