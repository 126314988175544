import React from 'react';
import clsx from 'clsx';
import { Typography, Box, makeStyles, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import appleIcon from '../../../assets/icons/apple-icon-gray.svg';
import googleAppIcon from '../../../assets/icons/Google-play-gray.svg';

// Element styling.
const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },
  buttonContainer: {
    backgroundColor: theme.palette.common.white,
    color: '#546e7a',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.75rem 0.25rem',
    textDecoration: 'none',
    borderRadius: '0.5rem',
    border: '2px solid #ccc',
    minWidth: '8rem',
    [theme.breakpoints.down('xs')]: {
      minWidth: '7rem',
    },
  },
  leftBtn: {
    marginRight: '1rem',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0.5rem',
    },
  },
  icon: {
    height: '1.5rem',
    display: 'inline-block',
    marginRight: '0.5rem',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0.25rem',
    },
  },
  texts: {
    display: 'inline-block',
  },
  firstText: {
    fontSize: '0.4rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    display: 'block',
  },
  seconedText: {
    display: 'inline-block',
    fontSize: '0.8rem',
    lineHeight: '1',
    fontWeight: '600',
  },
  link: {
    width: '100%',
    display: 'inline-block',
    textDecoration: 'none',
  },
  mainContainer: {
    marginLeft: '2%',
    width: '96%',
    marginTop: theme.spacing(2),
  },
  titleContainer: {
    marginLeft: theme.spacing(2.2),
  },
  titleText: {
    fontWeight: '500',
    fontSize: '15px',
    color: '#546e7a',
  },
  root: {
    marginTop: 'auto',
    marginBottom: theme.spacing(6),
  },
}));

const NavDownloadButton = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography className={classes.titleText}>
          {t('views.main.downloadTheApp')}
        </Typography>
      </div>
      <Grid
        container
        direction="column"
        justifyContent="center"
        spacing={3}
        className={classes.mainContainer}
      >
        <Grid item xs={11}>
          <a
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/app/swapbox-zero-waste-takeaway/id1563620024"
          >
            <Box className={clsx(classes.buttonContainer)}>
              <img
                src={appleIcon}
                alt={t('views.main.appStore')}
                className={classes.icon}
              />
              <Box className={classes.texts}>
                <Typography variant="body1" className={classes.firstText}>
                  {t('views.main.downloadOnThe')}
                </Typography>
                <Typography variant="body1" className={classes.seconedText}>
                  {t('views.main.appStore')}
                </Typography>
              </Box>
            </Box>
          </a>
        </Grid>
        <Grid item xs={11}>
          <a
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.swapbox.consumer"
          >
            <Box className={classes.buttonContainer}>
              <img
                src={googleAppIcon}
                alt={t('views.main.googlePlay')}
                className={classes.icon}
              />
              <Box className={classes.texts}>
                <Typography variant="body1" className={classes.firstText}>
                  {t('views.main.getItOn')}
                </Typography>
                <Typography variant="body1" className={classes.seconedText}>
                  {t('views.main.googlePlay')}
                </Typography>
              </Box>
            </Box>
          </a>
        </Grid>
      </Grid>
    </div>
  );
};

export default NavDownloadButton;
