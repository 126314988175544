import React, { createContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { THEMES } from '../constants';

const defaultSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: THEMES.LIGHT,
  language: 'en',
};

export const restoreSettings = () => {
  let settings = null;

  try {
    const storedData = localStorage.getItem('settings');

    if (storedData) {
      settings = JSON.parse(storedData);
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

// Store settings passed through params in local storage, as JSON string
export const storeSettings = (settings) => {
  localStorage.setItem('settings', JSON.stringify(settings));
};

// Create context so any child component at any depth will be able to access settings.
const SettingsContext = createContext({
  settings: defaultSettings,
  saveSettings: () => {},
});

export const SettingsProvider = ({ settings, children }) => {
  // State to store and set settings.
  const [currentSettings, setCurrentSettings] = useState(
    settings || defaultSettings
  );

  const { i18n } = useTranslation();

  // Any new settings, combine with current settings
  const handleSaveSettings = (update = {}) => {
    const mergedSettings = _.merge({}, currentSettings, update);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  // When state is updated, this will be executed. Will check settings is JSON string.
  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setCurrentSettings(restoredSettings);
    }
  }, []);

  // When state is updated, only when new and old values are different, this is executed. Updates text direction and language.
  useEffect(() => {
    document.dir = currentSettings.direction;
    i18n.changeLanguage(currentSettings.language);
  }, [currentSettings, i18n]);

  /* eslint-disable react/jsx-no-constructed-context-values */
  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  settings: PropTypes.object,
  children: PropTypes.node,
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
