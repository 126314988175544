import { createSlice } from '@reduxjs/toolkit';
import { axiosMockInstance as axios } from '../utils/axios';
import objFromArray from '../utils/objFromArray';

const initialState = {
  mails: {
    byId: {},
    allIds: [],
  },
  labels: [],
  isSidebarOpen: false,
  isComposeOpen: false,
};

const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    // Set labels.
    getLabels(state, action) {
      const { labels } = action.payload;

      state.labels = labels;
    },
    // Set mails. Create object where each property is a mail, and each key is the id the mail.
    // Also create array of mail ids.
    getMails(state, action) {
      const { mails } = action.payload;

      state.mails.byId = objFromArray(mails);
      state.mails.allIds = Object.keys(state.mails.byId);
    },
    // Add new mail to mails 'byId' object. Also add id to mails id array, if not already included.
    getMail(state, action) {
      const { mail } = action.payload;

      state.mails.byId[mail.id] = mail;

      if (!state.mails.allIds.includes(mail.id)) {
        state.mails.allIds.push(mail.id);
      }
    },
    // Set sidebar boolean to true (show sidebar).
    openSidebar(state) {
      state.isSidebarOpen = true;
    },
    // Set sidebar boolean to false (close/hide sidebar).
    closeSidebar(state) {
      state.isSidebarOpen = false;
    },
    // Set compose boolean to true (show compose).
    openCompose(state) {
      state.isComposeOpen = true;
    },
    // Set compose boolean to false (close/hide compose).
    closeCompose(state) {
      state.isComposeOpen = false;
    },
  },
});

export const { reducer } = slice;

// Set labels from http request response in state.
export const getLabels = () => async (dispatch) => {
  const response = await axios.get('/api/mail/labels');

  dispatch(slice.actions.getLabels(response.data));
};

// Set mails from http request response in state.
export const getMails = (params) => async (dispatch) => {
  const response = await axios.get('/api/mail/mails', {
    params,
  });

  dispatch(slice.actions.getMails(response.data));
};

// Set specific mail from http request response in state.
export const getMail = (mailId) => async (dispatch) => {
  const response = await axios.get('/api/mail/mail', {
    params: {
      mailId,
    },
  });

  dispatch(slice.actions.getMail(response.data));
};

// Change sidebar boolean in state to true.
export const openSidebar = () => async (dispatch) => {
  dispatch(slice.actions.openSidebar());
};

// Change sidebar boolean in state to false.
export const closeSidebar = () => async (dispatch) => {
  dispatch(slice.actions.closeSidebar());
};

// Change compose boolean in state to true.
export const openCompose = () => async (dispatch) => {
  dispatch(slice.actions.openCompose());
};

// Change compose boolean in state to false.
export const closeCompose = () => async (dispatch) => {
  dispatch(slice.actions.closeCompose());
};

export default slice;
